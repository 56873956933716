function isModernBrowser() {
  return window.fetch && Object.assign;
}

function loadApp() {
  import('./app'); // eslint-disable-line no-unused-expressions
}

function loadPolyfills(done) {
  import(
    /* webpackChunkName: "polyfills" */
    './polyfills'
  ).then(done);
}

if (isModernBrowser()) {
  loadApp();
} else {
  loadPolyfills(loadApp);
}
